<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters>
      <v-col cols="12" class="my-2">
        <span
          class="font-weight-medium text-body-1 primary--text text--darken-1"
          >{{ $t("deposit.depositActions") }}</span
        >
        <NextAndPreviousCommon
          :nextId="nextId"
          :prevId="prevId"
          :documentId="depositId"
          @idChange="redirectOnNextAndPrevious"
        />
      </v-col>
    </v-row>
    <v-row
      no-gutters
      v-if="depositId && !!model.is_locked && model.user_id !== user.id"
    >
      <v-col cols="12" class="my-3">
        <span
          class="font-weight-medium text-body-1 primary--text text--darken-1 error--text"
          >*{{
            $t("common.documentReadOnlyModeNotice", { userId: model.user.name })
          }}</span
        >
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card class="mx-auto" outlined>
          <v-card-title class="py-1">
            <h5
              style="max-width: 175px"
              :class="
                depositId
                  ? 'px-1 text-truncate font-italic primary--text font-weight-bold'
                  : 'primary--text text--darken-1'
              "
              class="font-weight-regular"
            >
              {{ depositId ? `${depositId}.` : $t("deposit.addDeposit") }}
            </h5>
            <v-spacer />
            <v-menu
              bottom
              v-if="depositId"
              :close-on-click="true"
              :offset-y="true"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :color="
                    remarksList && remarksList.length > 0
                      ? 'success'
                      : 'primary'
                  "
                  class="ma-2"
                  outlined
                  small
                  @click="remarksDialog = true"
                  :disabled="
                    accessRight.length < 2 && accessRight.includes('show')
                  "
                >
                  {{ $t("remarks.remarks") }}
                </v-btn>
                <v-btn
                  color="primary"
                  class="ma-2"
                  small
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  :disabled="
                    !valid || (!!model.is_locked && model.user_id !== user.id)
                  "
                >
                  <v-icon left> mdi-menu-down </v-icon>
                  {{ $t("quotation.options") }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  dense
                  class="primary--text"
                  @click="actDialog = true"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="primary--text"> mdi-pulse </v-icon>
                      {{ $t("quotation.activityLog") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  dense
                  class="primary--text"
                  @click="attachedDocumentsDialog = true"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="primary--text">
                        mdi-file-document
                      </v-icon>
                      {{ $t("employee.attachedDocuments") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  dense
                  :disabled="selectedStatus == 2"
                  v-if="accessRight.includes('delete')"
                  class="error--text"
                  @click="
                    (confirmationDialogStatus = true), (delete_item = depositId)
                  "
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="error--text">
                        mdi-delete-sweep-outline
                      </v-icon>
                      {{ $t("common.delete") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
            <HtmlToPdf
              v-if="depositId"
              class="me-2 mb-1"
              :disabled="
                valid && !(!!model.is_locked && model.user_id !== user.id)
              "
              :model="{ ...originModel, total: total }"
              type="DepositPDF"
            />
            <v-btn
              color="primary"
              small
              :loading="loading"
              :disabled="
                !valid ||
                (isFieldReadable &&
                  originModel.accounting_update &&
                  !!depositId) ||
                (!!model.is_locked && model.user_id !== user.id) ||
                (isFieldReadable &&
                  model.accounting_update &&
                  !!depositId &&
                  !!checkCustomerBookkeepingCount) ||
                (isFieldReadable && !model.accounting_update && !!depositId)
              "
              @click="saveDeposit"
            >
              {{ $t("deposit.save") }}
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text class="text-h5 pa-2">
            <FormSkeleton
              v-if="depositId && Object.keys(depositById).length <= 0"
            />
            <v-form v-else ref="form" v-model="valid" lazy-validation>
              <Alert :alert.sync="alert" :message="message" />
              <v-row>
                <v-col cols="12">
                  <v-card class="mx-auto" flat>
                    <v-card-title class="py-1 primary--text">
                      <v-icon color="primary"
                        >mdi-file-delimited-outline</v-icon
                      >
                      <h6 class="ms-2 font-weight-regular">
                        {{ $t("deposit.depositDetail") }}
                      </h6>
                      <v-spacer />
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-row :dense="true">
                        <v-col cols="3">
                          <v-menu
                            v-if="!isFieldReadable"
                            ref="startDateMenu"
                            v-model="startDateMenu"
                            :close-on-content-click="false"
                            :return-value.sync="model.deposit_date"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                dense
                                hide-details="auto"
                                v-model="formattedDepositDate"
                                :label="$t('deposit.depositDate')"
                                v-bind="attrs"
                                :rules="
                                  profileById.date_format
                                    ? dateValid
                                    : dateValid2
                                "
                                v-on="on"
                                readonly
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="model.deposit_date"
                              @change="
                                $refs.startDateMenu.save(model.deposit_date)
                              "
                            >
                              <v-btn
                                small
                                class="primary"
                                @click="$refs.startDateMenu.save(todayDate())"
                                >{{ $t("route.today") }}</v-btn
                              >
                            </v-date-picker>
                          </v-menu>
                          <v-text-field
                            v-else
                            dense
                            hide-details="auto"
                            v-model="formattedDepositDate"
                            :label="$t('deposit.depositDate')"
                            readonly
                          />
                        </v-col>
                        <v-col cols="3">
                          <v-select
                            :readonly="isFieldReadable"
                            dense
                            hide-details="auto"
                            :clearable="!isFieldReadable"
                            v-model="selectedPaymentType"
                            :items="paymentGroupValue"
                            :label="$t('deposit.type')"
                            item-text="description"
                            item-value="id"
                            return-object
                            @change="getPaymentData"
                            :menu-props="{ bottom: true, offsetY: true }"
                          />
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            hide-details="auto"
                            dense
                            v-model="selectedBank"
                            readonly
                            @click:clear="clearBank()"
                            :clearable="!isFieldReadable"
                            required
                            :label="$t('deposit.bank')"
                            @click="bankDialog = isFieldReadable ? false : true"
                          />
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            hide-details="auto"
                            dense
                            v-model="model.employee_name"
                            readonly
                            required
                            :label="$t('deposit.agent')"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-card class="mx-auto" flat>
                    <v-card-title class="py-1 primary--text">
                      <v-row dense no-gutters>
                        <v-col cols="12" class="d-flex">
                          <v-icon color="primary">mdi-cart-plus</v-icon>
                          <h6
                            class="ms-2 align-self-center font-weight-regular"
                          >
                            {{ $t("deposit.listOfPayment") }}
                          </h6>
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-row :dense="true">
                        <v-col cols="12">
                          <v-simple-table
                            dense
                            class="custom-table elevation-1 item-table"
                            fixed-header
                            height="250"
                          >
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th
                                    :style="{ width: '20px' }"
                                    v-if="!depositId"
                                  >
                                    <v-checkbox
                                      :disabled="paymentItems.length <= 0"
                                      x-small
                                      @click="selectAllDipositItem"
                                      v-model="allSelectedItem"
                                      dense
                                      hide-details
                                    />
                                  </th>
                                  <th
                                    :style="{ width: '210px' }"
                                    :class="{
                                      active: sortColumnKey === 'customer_name',
                                    }"
                                    @click="sortColumn('customer_name')"
                                  >
                                    {{ $t("deposit.customerName") }}
                                    <i
                                      class="mdi mdi-chevron-up"
                                      :class="{
                                        active:
                                          sortColumnKey === 'customer_name' &&
                                          sortDirection === 'asc',
                                      }"
                                    ></i>
                                  </th>
                                  <th
                                    :style="{ width: '120px' }"
                                    :class="{
                                      active: sortColumnKey === 'cheque_no',
                                    }"
                                    @click="sortColumn('cheque_no')"
                                  >
                                    {{ $t("deposit.chequeNumber") }}
                                    <i
                                      class="mdi mdi-chevron-up"
                                      :class="{
                                        active:
                                          sortColumnKey === 'cheque_no' &&
                                          sortDirection === 'asc',
                                      }"
                                    ></i>
                                  </th>
                                  <th
                                    :style="{ width: '140px' }"
                                    :class="{
                                      active: sortColumnKey === 'bank_name',
                                    }"
                                    @click="sortColumn('bank_name')"
                                  >
                                    {{ $t("deposit.bank") }}
                                    <i
                                      class="mdi mdi-chevron-up"
                                      :class="{
                                        active:
                                          sortColumnKey === 'bank_name' &&
                                          sortDirection === 'asc',
                                      }"
                                    ></i>
                                  </th>
                                  <th
                                    :style="{ width: '100px' }"
                                    :class="{
                                      active: sortColumnKey === 'cheque_date',
                                    }"
                                    @click="sortColumn('cheque_date')"
                                  >
                                    {{ $t("deposit.field.paymentDate") }}
                                    <i
                                      class="mdi mdi-chevron-up"
                                      :class="{
                                        active:
                                          sortColumnKey === 'cheque_date' &&
                                          sortDirection === 'asc',
                                      }"
                                    ></i>
                                  </th>
                                  <th
                                    v-if="
                                      !depositId ||
                                      (depositId &&
                                        accessRight.includes('financial'))
                                    "
                                    :style="{ width: '100px' }"
                                    @click="sortColumn('amount')"
                                    :class="{
                                      active: sortColumnKey === 'amount',
                                    }"
                                  >
                                    {{ $t("deposit.amount") }}
                                    <i
                                      class="mdi mdi-chevron-up"
                                      :class="{
                                        active:
                                          sortColumnKey === 'amount' &&
                                          sortDirection === 'asc',
                                      }"
                                    ></i>
                                  </th>
                                  <th
                                    :style="{ width: '100px' }"
                                    :class="{
                                      active: sortColumnKey === 'type',
                                    }"
                                    @click="sortColumn('type')"
                                  >
                                    {{ $t("deposit.type") }}
                                    <i
                                      class="mdi mdi-chevron-up"
                                      :class="{
                                        active:
                                          sortColumnKey === 'type' &&
                                          sortDirection === 'asc',
                                      }"
                                    ></i>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-if="paymentItems.length <= 0">
                                  <td colspan="8" class="text-center">
                                    {{ $t("common.noDataFound") }}
                                  </td>
                                </tr>
                                <tr
                                  v-else
                                  style="vertical-align: bottom"
                                  v-for="(item, index) in paymentItems"
                                  :key="index"
                                  :class="
                                    index + 1 == paymentItems.length
                                      ? 'newRow'
                                      : ''
                                  "
                                >
                                  <td v-if="!depositId">
                                    <v-checkbox
                                      x-small
                                      @click="selectedDepositItem"
                                      v-model="selectedItem"
                                      :value="item"
                                      dense
                                      hide-details
                                    />
                                  </td>
                                  <td>
                                    {{ item.customer_name }}
                                  </td>
                                  <td>
                                    {{ item.cheque_no }}
                                  </td>
                                  <td>
                                    {{ item.bank_name }}
                                  </td>
                                  <td>
                                    {{
                                      profileById.date_format
                                        ? formatDateDDMMYYYY(item.cheque_date)
                                        : item.cheque_date
                                    }}
                                  </td>
                                  <td
                                    v-if="
                                      !depositId ||
                                      (depositId &&
                                        accessRight.includes('financial'))
                                    "
                                  >
                                    {{ numberWithCommas(item.amount) }}
                                  </td>
                                  <td>
                                    {{ item.type }}
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-col>
                      </v-row>
                      <v-row :dense="true" class="pt-6">
                        <v-col cols="8">
                          <v-row :dense="true">
                            <v-col cols="8">
                              <v-row>
                                <v-col cols="6">
                                  <v-select
                                    hide-details="auto"
                                    dense
                                    readonly
                                    v-model="selectedStatus"
                                    :items="statusList"
                                    item-text="name"
                                    item-value="id"
                                    return-object
                                    :menu-props="{
                                      bottom: true,
                                      offsetY: true,
                                    }"
                                    :label="$t('deposit.field.status')"
                                  />
                                </v-col>
                                <v-col cols="6">
                                  <v-checkbox
                                    :dense="true"
                                    :readonly="
                                      (!!depositId &&
                                        originModel.status == 1 &&
                                        !originModel.bank.accounting_no) ||
                                      !depositId ||
                                      (originModel.status == 0 && !depositId) ||
                                      (!!depositId &&
                                        originModel.status == 0 &&
                                        !checkCustomerBookkeepingCount &&
                                        !!originModel.accounting_update) ||
                                      (!checkCustomerBookkeepingCount &&
                                        originModel.accounting_update == 1)
                                    "
                                    @click="
                                      bookkeepingConfirmationDialog =
                                        !!depositId &&
                                        originModel.status == 0 &&
                                        checkCustomerBookkeepingCount &&
                                        !originModel.accounting_update
                                          ? true
                                          : false
                                    "
                                    v-model="model.accounting_update"
                                    @click.native.stop="
                                      bookkeepingRecordsDialog =
                                        (originModel.accounting_update &&
                                          originModel.status == 1) ||
                                        originModel.status == 2
                                          ? true
                                          : false
                                    "
                                    :label="$t('deposit.field.accountUpdate')"
                                    v-on:click="
                                      alertConfirmationDialog =
                                        !!depositId &&
                                        originModel.status == 1 &&
                                        !originModel.bank.accounting_no &&
                                        (!!depositId &&
                                        !originModel.accounting_update
                                          ? true
                                          : false)
                                    "
                                  />
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-divider class="mx-8" vertical />
                        <v-col
                          cols="3"
                          v-if="
                            !depositId ||
                            (depositId && accessRight.includes('financial'))
                          "
                        >
                          <v-row dense>
                            <v-col cols="6" class="font-weight-bold">
                              {{ $t("delivery.total") }}
                            </v-col>
                            <v-col
                              cols="6"
                              class="font-weight-bold"
                              :class="
                                locale === 'he' ? 'text-start' : 'text-end'
                              "
                            >
                              {{ numberWithCommas(total) }}
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-overlay :z-index="999" v-if="isProgressCircular">
        <v-icon size="64" class="hourglass-icon" color="primary"
          >mdi-timer-sand-paused</v-icon
        >
      </v-overlay>
      <ActivityLogTableDialog
        :actDialog.sync="actDialog"
        :docId.sync="depositId"
        docType="Deposit"
        docMode="sales"
        docItems="DepositSub"
        docEmailSent="DepositEmailSent"
      />
      <BookkeepingRecordsDialog
        :bookkeepingRecordsDialog.sync="bookkeepingRecordsDialog"
        type="Deposit"
        :docId.sync="depositId"
        docMode="sales"
      />
      <BankDialog @onBankChange="onBankChange" :dialog.sync="bankDialog" />
      <RemarksDialog
        :dialog.sync="remarksDialog"
        :status="originModel.status"
        :docId="depositId"
        :docType="12"
      />
      <BookkeepingConfirmationDialog
        :dialog.sync="bookkeepingConfirmationDialog"
        :bookkeepingConfirmation="bookkeepingConfirmation"
      />
      <AlertConfirmationDialog
        :dialog.sync="alertConfirmationDialog"
        :alertConfirmation="alertConfirmation"
      />
      <confirmation-dialog
        :dialog.sync="confirmationDialogStatus"
        :delete_item.sync="delete_item"
        @deleteItem="deleteItem"
      />
      <AttachedDocumentsDialog
        :docId="depositId"
        docType="Deposit"
        :dialog.sync="attachedDocumentsDialog"
      />
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { getAuxiliaryZtableValueByName, changedFormData } from "@/utils";
import FormSkeleton from "@/components/skeleton/FormSkeleton";
import Alert from "@/components/Alert";
import ActivityLogTableDialog from "@/components/activityLog/Dialogs/ActivityLogTableDialog.vue";
import BookkeepingRecordsDialog from "@/components/bookkeepingRecords/Dialogs/BookkeepingRecordsDialog.vue";
import BankDialog from "@/components/moduleTableDialog/Dialog/BankDialog";
import HtmlToPdf from "@/components/generatePDF/HtmlToPdf";
import { formatDateDDMMYYYY, todayDate } from "../../../utils";
import RemarksDialog from "@/components/RemarksDialog";
import BookkeepingConfirmationDialog from "@/components/BookkeepingConfirmationDialog";
import AlertConfirmationDialog from "@/components/AlertConfirmationDialog.vue";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import AttachedDocumentsDialog from "@/components/AttachedDocumentsDialog";
import NextAndPreviousCommon from "@/components/NextAndPreviousCommon";
import { validationMixin } from "@/mixins/validationMixin";

export default {
  name: "DepositAction",
  components: {
    FormSkeleton,
    Alert,
    ActivityLogTableDialog,
    BookkeepingRecordsDialog,
    BankDialog,
    HtmlToPdf,
    RemarksDialog,
    BookkeepingConfirmationDialog,
    AlertConfirmationDialog,
    ConfirmationDialog,
    AttachedDocumentsDialog,
    NextAndPreviousCommon,
  },
  mixins: [validationMixin],
  data() {
    return {
      message: "",
      alert: false,
      loading: false,
      valid: false,
      confirmationDialog: false,
      setOrderStatus: null,
      model: {
        status: 0,
        deposit_date: "",
      },
      formattedDepositDate: "",
      total: 0.0,
      originModel: {},
      selectedBank: null,
      selectedPaymentType: null,
      actDialog: false,
      startDateMenu: false,
      paymentItems: [],
      sortBy: "",
      sortDirection: "asc",
      sortColumnKey: "",
      paymentGroupValue: [],
      selectedStatus: 0,
      bankDialog: false,
      bankList: [],
      nextId: null,
      prevId: null,
      selectedItem: [],
      allSelectedItem: false,
      remarksDialog: false,
      bookkeepingConfirmationDialog: false,
      bookkeepingRecordsDialog: false,
      alertConfirmationDialog: false,
      delete_item: null,
      attachedDocumentsDialog: false,
      confirmationDialogStatus: false,
      isProgressCircular: false,
    };
  },
  computed: {
    statusList() {
      return [
        { name: this.$t("deposit.field.open"), id: 0 },
        { name: this.$t("deposit.field.finished"), id: 1 },
        { name: this.$t("deposit.field.canceled"), id: 2 },
      ];
    },
    depositId() {
      return this.$route.params.deposit_id;
    },
    ...mapGetters({
      depositById: "deposit/depositById",
      paymentList: "deposit/paymentList",
      locale: "locale",
      depositQuery: "deposit/depositQuery",
      accessRight: "accessRight",
      remarksList: "remarks/remarksList",
      user: "user",
      profileById: "profile/profileById",
      moreThanOneUserLogout: "moreThanOneUserLogout",
      bookkeepingConfirmation: "bookkeepingConfirmation",
      alertConfirmation: "alertConfirmation",
    }),
    isFieldReadable() {
      return this.model.status == 0 ? (this.depositId ? true : false) : true;
    },
    checkCustomerBookkeepingCount() {
      var deposit_sub_array = this.depositById;
      var count = 0;
      for (let i = 0; i < deposit_sub_array.depositSub.length; i++) {
        if (!deposit_sub_array.depositSub[i].customers[0].book_kiping_no) {
          count++;
        }
      }
      return count;
    },
  },
  watch: {
    "model.deposit_date"(val) {
      this.formattedDepositDate = this.profileById.date_format
        ? val
        : formatDateDDMMYYYY(val);
    },
    profileById(val) {
      this.formattedDepositDate = val.date_format
        ? this.model.deposit_date
        : formatDateDDMMYYYY(this.model.deposit_date);
    },
    paymentList() {
      this.paymentList.forEach((item) => {
        if (item.receipts) {
          this.paymentItems.push({
            receipt_payment_id: item.id,
            customer_id: item.receipts.customer_id,
            customer_name: item.receipts.customer.name_1,
            cheque_no: item.cheque_no,
            cheque_date: item.payment_date,
            bank_name: item.bank,
            type: item.paymentTypes.description,
            branch: item.branch,
            account_no: item.account_no,
            amount: item.amount,
            deposit_to_bank: null,
            document_type: 24,
          });
        } else {
          this.paymentItems.push({
            receipt_payment_id: item.id,
            customer_id: item.receiptTaxInvoices.customer_id,
            customer_name: item.receiptTaxInvoices.customer.name_1,
            cheque_no: item.cheque_no,
            cheque_date: item.receipt_tax_invoice_payment_date,
            bank_name: item.bank,
            type: item.paymentTypes.description,
            branch: item.branch,
            account_no: item.account_no,
            amount: item.amount,
            deposit_to_bank: null,
            document_type: 20,
          });
        }
      });
    },
    depositById() {
      if (this.$route.params.deposit_id) {
        if (Object.keys(this.depositById).length > 0) {
          this.model = Object.assign({}, this.model, this.depositById);
          if (this.model.deposit_date) {
            this.model.deposit_date = new Date(this.model.deposit_date)
              .toISOString()
              .substring(0, 10);
          }
          this.selectedStatus = this.model.status;
          this.originModel = Object.assign({}, this.originModel, this.model);
        }
      }
    },
    async moreThanOneUserLogout() {
      if (this.moreThanOneUserLogout && this.model.id) {
        await this.$store.dispatch("PostDocumentOpenAction", {
          table: "Deposit",
          id: this.model.id,
          is_locked: false,
        });
        this.$store.dispatch("LogOut");
        this.$router.push({ name: "login" });
      }
    },
    bookkeepingConfirmation: {
      handler: function (val) {
        if (val) {
          this.bookkeepingConfirmationDialog = true;
        }
      },
      deep: true,
    },
    alertConfirmation: {
      handler: function (val) {
        if (val) {
          this.alertConfirmationDialog = true;
        }
      },
    },
  },
  async created() {
    window.addEventListener("beforeunload", this.handler);
  },
  async mounted() {
    if (this.depositId) {
      this.$store.dispatch("remarks/GetRemarks", {
        where: "document_type|" + 12,
        where_and: "document_id|" + this.depositId,
      });
    }
    this.onMounteUpdated();
  },
  beforeDestroy() {
    if (
      (this.model.id &&
        !this.moreThanOneUserLogout &&
        this.model.is_locked &&
        this.model.user_id == this.user.id) ||
      (this.model.id && !this.model.user_id && !this.moreThanOneUserLogout)
    ) {
      this.$store.dispatch("PostDocumentOpenAction", {
        table: "Deposit",
        id: this.model.id,
        is_locked: false,
      });
    }
    this.$refs.form ? this.$refs.form.reset() : "";
    this.model = {};
    this.dialog = false;
    this.$store.commit("deposit/SET_DEPOSIT_BY_ID", {});
    this.$store.commit("deposit/SET_PAYMENT", []);
    this.$store.commit("SET_USER_LOGOUT", false);
    window.removeEventListener("beforeunload", this.handler);
  },
  methods: {
    formatDateDDMMYYYY,
    todayDate,
    async handler(event) {
      if (
        (this.model.id &&
          !this.moreThanOneUserLogout &&
          this.model.is_locked &&
          this.model.user_id == this.user.id) ||
        (this.model.id && !this.model.user_id && !this.moreThanOneUserLogout)
      ) {
        await this.$store.dispatch("PostDocumentOpenAction", {
          table: "Deposit",
          id: this.model.id,
          is_locked: false,
        });
      }
      event.preventDefault();
    },
    async onMounteUpdated() {
      if (this.$route.params.deposit_id) {
        this.documentNextAndPrevious();
        this.bankList = await this.$store.dispatch("bank/GetBank", {
          order_by: "name|asc",
        });
        if (this.$route.params.deposit_id) {
          await this.$store.dispatch(
            "deposit/GetDepositById",
            this.$route.params.deposit_id
          );
        }
        if (!this.model.is_locked && this.$route.params.deposit_id) {
          await this.$store.dispatch("PostDocumentOpenAction", {
            table: "Deposit",
            id: this.$route.params.deposit_id,
            is_locked: true,
          });
        }
        if (this.model.bank_id) {
          this.selectedBank = this.model?.bank?.name;
          this.model.bank_id = this.model?.bank_id;
        }
        if (this.model.type) {
          this.selectedPaymentType = this.model?.type;
        }
        // payment detail
        this.paymentItems = [];
        this.model.depositSub.forEach((element) => {
          delete element["created_at"];
          delete element["updated_at"];
          this.paymentItems.push(Object.assign({}, { ...element }));
          this.selectedItem.push(Object.assign({}, { ...element }));
        });
        this.depositPriceCalculation();
      } else {
        this.model.employee_name = this.user.name;
        this.bankList = await this.$store.dispatch("bank/GetBank", {
          order_by: "name|asc",
        });
        this.model.deposit_date = new Date().toISOString().substring(0, 10);
      }
      await this.$store.dispatch("profile/GetCompanyById");
      let tableNameValue = await getAuxiliaryZtableValueByName(8);
      if (Object.keys(tableNameValue).length > 0) {
        let paymentGroupValue = tableNameValue.ztables.sort((a, b) =>
          a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()
            ? 1
            : -1
        );
        this.paymentGroupValue = paymentGroupValue.filter(
          (item) =>
            item.description !== "Bank transfer" && item.description !== "העברה"
        );
      }
    },
    selectAllDipositItem() {
      this.selectedItem = [];
      if (this.allSelectedItem) {
        for (let payment in this.paymentItems) {
          this.selectedItem.push(this.paymentItems[payment]);
          this.selectedItem.filter(
            (item) => (item.deposit_to_bank = this.model.bank_id)
          );
          this.depositPriceCalculation();
        }
      } else {
        this.paymentItems.forEach((item) => (item.deposit_to_bank = null));
        this.depositPriceCalculation();
      }
    },
    selectedDepositItem() {
      if (this.paymentItems.length == this.selectedItem.length) {
        this.allSelectedItem = true;
      } else {
        this.allSelectedItem = false;
        this.paymentItems.forEach((item) => (item.deposit_to_bank = null));
      }
      this.selectedItem.filter(
        (item) => (item.deposit_to_bank = this.model.bank_id)
      );
      this.depositPriceCalculation();
    },
    async documentNextAndPrevious(customerId = null) {
      if (this.depositId) {
        let docPagination = await this.$store.dispatch(
          "GetDocumentPagination",
          {
            type: "deposits",
            customer_id: customerId,
            document_id: Number(this.depositId),
          }
        );
        this.nextId = docPagination.nextId ? docPagination.nextId : null;
        this.prevId = docPagination.prevId ? docPagination.prevId : null;
      }
    },
    redirectOnNextAndPrevious(id) {
      if (id) {
        this.$router.push({ path: "/deposit/DepositAction/" + id });
      } else {
        return false;
      }
    },
    async getPaymentData() {
      let type = null;
      this.paymentItems = [];
      if (
        this.selectedPaymentType &&
        Object.keys(this.selectedPaymentType).length > 0
      ) {
        type = this.selectedPaymentType.id;
      } else {
        type = this.selectedPaymentType;
      }
      if (type) {
        await this.$store.dispatch("deposit/GetPayment", {
          where_and: "is_deposited,payment_terms_id|0," + type,
        });
        await this.$store.dispatch("deposit/GetReceiptTaxInvoicePayments", {
          where_and: "is_deposited,payment_terms_id|0," + type,
        });
      }
    },
    setBankDetail(index) {
      this.paymentItems[index].deposit_to_bank = this.model.bank_id;
      this.depositPriceCalculation();
    },
    clearBankDetail(index) {
      this.paymentItems[index].deposit_to_bank = null;
      this.depositPriceCalculation();
    },
    clearBank() {
      this.selectedBank = null;
      this.model.bank_id = null;
      this.paymentItems.forEach((item) => {
        item.deposit_to_bank = null;
      });
    },
    callBackFunction() {
      this.$router.push({
        name: "Deposit",
        query: {
          ...this.depositQuery,
        },
      });
    },
    numberFromCommas(x) {
      return x ? x.toString().replace(/,/g, "") : null;
    },
    numberWithCommas(x, type = "float") {
      return x
        ? type === "float"
          ? parseFloat(x)
              .toFixed(2)
              .toString()
              .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
          : x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
        : type === "float"
        ? (0).toFixed(2)
        : x;
    },
    depositPriceCalculation() {
      this.total =
        this.selectedItem.length > 0
          ? this.selectedItem
              .reduce(function (amount, item) {
                return parseFloat(item.amount)
                  ? parseFloat(amount) + parseFloat(item.amount)
                  : amount + 0;
              }, 0)
              .toFixed(2)
          : 0;
    },
    onBankChange(item) {
      this.selectedBank = item.name;
      this.model.bank_id = item.id;
      this.paymentItems.forEach((item) => {
        item.deposit_to_bank = null;
      });
      this.selectedItem.filter(
        (item) => (item.deposit_to_bank = this.model.bank_id)
      );
    },
    lessThanValidation(fieldValue, typeValue, sizeValue, v) {
      return (
        !v ||
        (v && String(v).length <= sizeValue) ||
        this.$t("formRules.lengthValidMessage", {
          fieldName: fieldValue,
          type: typeValue,
          size: sizeValue,
        })
      );
    },
    sortColumn(key) {
      this.selectedItem = [];
      this.allSelectedItem = false;
      if (key === this.sortColumnKey) {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      } else {
        this.sortColumnKey = key;
        this.sortDirection = "asc";
      }
      this.paymentItems.sort((a, b) => {
        const aValue = a[key];
        const bValue = b[key];
        if (!isNaN(aValue) && !isNaN(bValue)) {
          return this.sortDirection === "asc"
            ? aValue - bValue
            : bValue - aValue;
        }
        if (this.sortDirection === "asc") {
          return aValue.localeCompare(bValue, undefined, { numeric: true });
        } else {
          return bValue.localeCompare(aValue, undefined, { numeric: true });
        }
      });
    },
    async saveDeposit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let data;
        let paymentItems;
        paymentItems = this.selectedItem.filter((item) => {
          if (item.deposit_to_bank) {
            delete item["year"];
            delete item["startDateMenu"];
            if (
              item.deposit_to_bank &&
              Object.keys(item.deposit_to_bank).length > 0
            ) {
              item.deposit_to_bank = item.deposit_to_bank.id;
            }
            item.amount =
              this.numberFromCommas(item.amount) === ""
                ? null
                : this.numberFromCommas(item.amount);
            return item;
          }
        });
        this.model.paymentData = paymentItems;
        if (
          this.selectedStatus &&
          Object.keys(this.selectedStatus).length > 0
        ) {
          this.model.status = this.selectedStatus.id;
        } else {
          this.model.status = this.selectedStatus;
        }
        if (
          this.selectedPaymentType &&
          Object.keys(this.selectedPaymentType).length > 0
        ) {
          this.model.type = this.selectedPaymentType.id;
        } else {
          this.model.type = this.selectedPaymentType;
        }
        if (this.model.paymentData.length > 0) {
          if (this.$route.params.deposit_id) {
            // save only update model value using changedFormData from utils
            this.model.status = 1;
            this.model.total = this.total;
            let saveData = changedFormData(this.model, this.originModel);
            this.isProgressCircular = true;
            data = await this.$store
              .dispatch("deposit/EditDeposit", {
                deposit_id: this.$route.params.deposit_id,
                deposit: saveData,
              })
              .catch((e) => {
                this.loading = false;
                console.log(e);
              });
            if (data) {
              this.$refs.form.reset();
              this.loading = false;
              await this.onMounteUpdated();
            }
            this.isProgressCircular = false;
          } else {
            this.model.status = 1;
            this.model.total = this.total;
            data = await this.$store
              .dispatch("deposit/SetDeposit", this.model)
              .catch((e) => {
                this.loading = false;
                console.log(e);
              });
            if (data) {
              this.$refs.form.reset();
              this.loading = false;
              this.model.total = this.total;
              if (this.accessRight.includes("edit")) {
                await this.$router
                  .push("/deposit/depositAction/" + data.data.id)
                  .catch(() => {});
                if (data?.data?.abc?.type === "confirmation") {
                  this.$store.commit(
                    "SET_BOOKKEEPING_CONFIRMATION",
                    data.data.abc
                  );
                } else {
                  this.$store.commit("SET_ALERT_CONFIRMATION", data.data.abc);
                }
              } else {
                this.callBackFunction();
              }
            }
          }
        } else {
          this.message = "formRules.paymentItemAlert";
          this.alert = true;
          this.loading = false;
        }
      } else {
        this.message = "formRules.formAlert";
        this.alert = true;
      }
    },
    async deleteItem() {
      this.isProgressCircular = true;
      await this.$store.dispatch(
        "deposit/DeleteDepositItemById",
        this.$route.params.deposit_id
      );
      this.$router
        .push("/deposit/depositAction/" + this.$route.params.deposit_id)
        .catch(() => {});
      this.model.status = 2;
      this.originModel.status = 2;
      this.selectedStatus = 2;
      this.isProgressCircular = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.date-input ::v-deep input {
  font-size: 12px;
}
th.active {
  font-weight: bold;
  color: #000;
}
th:not(.active) {
  font-weight: normal;
  color: #666;
}
th {
  cursor: pointer;
}
::v-deep .custom-table ::-webkit-scrollbar {
  width: 12px;
}
</style>
